"use client";

import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

import { cn } from "@/lib/utils";

import CopyLink from "@/components/copy-link";
import ChevronToggle from "@/components/icons/chevron-toggle";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
	<AccordionPrimitive.Item ref={ref} className={cn("mt-12 scroll-mt-[76px]", className)} {...props} />
));
AccordionItem.displayName = AccordionPrimitive.Item.displayName;

const AccordionTrigger = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
		as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
		copyLinkUrl?: string;
		hideChevron?: boolean;
	}
>(
	(
		{
			className,
			children,
			as: HComp = "h3",
			copyLinkUrl,
			asChild = true,
			hideChevron = false,
			...props
		},
		ref
	) => (
		<AccordionPrimitive.Header asChild>
			<AccordionPrimitive.Trigger
				ref={ref}
				className={cn(
					"group relative flex w-full items-center justify-between text-left font-lora font-medium text-gray-900 data-[state=open]:text-primary cursor-pointer",
					className
				)}
				{...props}
				asChild
			>
				<HComp className="!mt-0 w-full">
					<span className="flex w-full items-center">{children}</span>
					{!hideChevron && (
						<ChevronToggle className="lg:absolute lg:-left-7 lg:top-1/2 lg:-ml-4 lg:-translate-y-1/2" />
					)}
					{copyLinkUrl ? <CopyLink url={copyLinkUrl} /> : null}
				</HComp>
			</AccordionPrimitive.Trigger>
		</AccordionPrimitive.Header>
	)
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
	<AccordionPrimitive.Content
		ref={ref}
		className={cn(
			// "data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
			"overflow-hidden",
			className
		)}
		{...props}
	>
		{children}
	</AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
