"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { MessageSquareText } from "lucide-react";
import { FeedbackFish as FeedbackFishComponent } from "@feedback-fish/react";
import { useMediaQuery } from "usehooks-ts";

import { useUser } from "@/lib/hooks/auth";
import { ClientOnly as ClientOnlyComponent } from "@/components/client-only";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

interface FeedbackFishWidgetProps {
	children: React.ReactNode;
	className?: string;
	variant?: "primary" | "outline";
}

const FeedbackFishWidget = ({
	children = "Send feedback",
	className,
	variant = "primary",
}: FeedbackFishWidgetProps) => {
	const feedbackFishId = process.env.NEXT_PUBLIC_FEEDBACK_FISH_ID || "";
	const searchParams = useSearchParams();
	const { user } = useUser();
	const pathname = usePathname();
	const params = `?${searchParams.toString()}`;
	const isMobile = useMediaQuery("(max-width: 768px)", { defaultValue: true });

	if (!feedbackFishId) return;

	return (
		<ClientOnlyComponent>
			<FeedbackFishComponent
				projectId={feedbackFishId}
				userId={user?.email || ""}
				metadata={{ path: pathname + params, user: user?.email || "" }}
			>
				<Button
					variant={variant}
					size="md"
					className={cn(
						`mt-4 w-full justify-center gap-1 space-x-2 whitespace-nowrap rounded-full text-center font-bold xl:px-5 xl:py-2`,
						className
					)}
				>
					<MessageSquareText className="h-4 w-4 lg:h-5 lg:w-5" />
					{isMobile ? null : children}
				</Button>
			</FeedbackFishComponent>
		</ClientOnlyComponent>
	);
};

export default FeedbackFishWidget;
