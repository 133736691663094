"use client";

import { useEffect, useState } from "react";

interface ClientOnlyProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export function ClientOnly({ children }: ClientOnlyProps) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return children;
}
