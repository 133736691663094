"use client";

import * as React from "react";

import { cn } from "@/lib/utils";
import { ChevronRight } from "lucide-react";

interface ChevronToggleProps extends React.HTMLAttributes<HTMLElement> {}

const ChevronToggle = ({ className }: ChevronToggleProps) => (
  <span
    className={cn(
      "grid size-7 rotate-0 place-content-center rounded-full bg-examine-purple-100/50 text-black transition-transform delay-75 ease-in-out",
      className
    )}
  >
    <ChevronRight
      className={cn(
        "size-5 rotate-0 stroke-[3] transition-transform delay-75 ease-in-out group-data-[state=open]:rotate-90"
      )}
    />
  </span>
);

export default ChevronToggle;
